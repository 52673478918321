<template>
  <div class="content">
    <el-form ref="form" label-width="94px">
      <el-form-item
        v-for="(item, $index) in modelList"
        :key="$index"
        :label="item.label"
      >
        <template v-if="item.type === 0">
          <el-input
            v-model="item.value"
            required
            placeholder="请输入内容"
            @change="
              (value) => {
                changeItem(value, $index);
              }
            "
          />
        </template>
        <template v-if="item.type === 8">
          <el-input
            v-model="item.value"
            type="password"
            required
            placeholder="请输入内容"
            @change="
              (value) => {
                changeItem(value, $index);
              }
            "
          />
        </template>
        <template v-if="item.type === 2">
          <el-select
            v-model="item.value"
            style="width: 100%"
            placeholder="请选择内容"
            filterable
            @change="
              (value) => {
                changeItem(value, $index);
              }
            "
          >
            <el-option
              v-for="option in item.option"
              :key="option.value"
              :label="option.label"
              :value="option.value"
            >
            </el-option>
          </el-select>
        </template>
        <template v-if="item.type === 1 || item.type === 200">
          <el-select
            v-model="item.value"
            multiple
            filterable
            style="width: 100%"
            placeholder="请选择内容"
            @change="
              (value) => {
                changeItem(value, $index);
              }
            "
          >
            <el-option
              v-for="option in item.option"
              :key="option.value"
              :label="option.label"
              :value="option.value"
            >
            </el-option>
          </el-select>
        </template>
        <template v-if="item.type === 5">
          <el-date-picker
            v-model="item.value"
            style="width: 290px"
            align="center"
            value-format="yyyy-MM-dd"
            type="date"
            placeholder="选择日期"
            @change="
              (value) => {
                changeItem(value, $index);
              }
            "
          >
          </el-date-picker>
        </template>
        <template v-if="item.type === 6">
          <el-date-picker
            v-model="item.value"
            style="width: 290px"
            align="center"
            type="datetime"
            value-format="yyyy-MM-dd HH:mm:ss"
            placeholder="选择日期时间"
            @change="
              (value) => {
                changeItem(value, $index);
              }
            "
          >
          </el-date-picker>
        </template>
        <template v-if="item.type === 7">
          <el-date-picker
            v-model="item.value"
            style="width: 290px"
            align="center"
            type="daterange"
            range-separator="-"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            value-format="yyyy-MM-dd"
            unlink-panels
            :picker-options="pickerOptions"
            @change="
              (value) => {
                changeItem(value, $index);
              }
            "
          >
          </el-date-picker>
        </template>
        <template v-if="item.type === 4">
          <el-input-number
            v-model="item.value"
            controls-position="right"
            :min="item.option[0].min"
            :max="item.option[0].max"
            :precision="item.option[0].precision"
            @change="
              (value) => {
                changeItem(value, $index);
              }
            "
          ></el-input-number>
        </template>
        <template v-if="item.type === 3">
          <number-range
            v-model="item.value"
            :min="item.option[0].min"
            :max="item.option[0].max"
            :precision="item.option[0].precision"
            @change="
              (value) => {
                changeItem(value, $index);
              }
            "
          >
          </number-range>
        </template>
        <template v-if="item.type === 300">
          <base-muti-input
            ref="baseMutiInput"
            v-model="item.value"
            style="width: 290px"
            @change="
              (value) => {
                changeItem(value, $index);
              }
            "
          ></base-muti-input>
        </template>
      </el-form-item>
    </el-form>
    <div class="btn" @click="dialogFormVisible = true">
      <i class="el-icon-plus"></i>
      新增筛选项
    </div>
    <ChooseFilterDialog
      :dialogFormVisible="dialogFormVisible"
      :filterListJson="filterListJson"
      :filterListId="filterListId"
      :tableCode="tableCode"
      @ok="ok"
      @cancel="dialogFormVisible = false"
    ></ChooseFilterDialog>
  </div>
</template>
<script>
import ChooseFilterDialog from '../dialog/ChooseFilterDialog.vue'
import BaseMutiInput from '../base/BaseMutiInput.vue'
export default {
  components: { ChooseFilterDialog, BaseMutiInput },
  props: {
    filterListJson: {
      type: Array,
      default: function() {
        return []
      }
    },
    companyDrapdown: { // 客户
      type: Array,
      default: function() {
        return []
      }
    },
    pickUpOptions: { // 提货车行
      type: Array,
      default: function() {
        return []
      }
    },
    originOptions: { // 始发地
      type: Array,
      default: function() {
        return []
      }
    },
    destinationOptions: { // 目的地
      type: Array,
      default: function() {
        return []
      }
    },
    userOptions: { // 销售员
      type: Array,
      default: function() {
        return []
      }
    },
    filterListId: {
      type: String,
      default: ''
    },
    tableCode: {
      type: String,
      default: 'MBLHoldRecord' // MBLHoldRecord MBLSea MBLAir
    }
  },
  data() {
    return {
      pickerOptions: {
          shortcuts: [{
            text: '最近一周',
            onClick(picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7)
              picker.$emit('pick', [start, end])
            }
          }, {
            text: '最近一个月',
            onClick(picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30)
              picker.$emit('pick', [start, end])
            }
          }, {
            text: '最近三个月',
            onClick(picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90)
              picker.$emit('pick', [start, end])
            }
          }]
      },
      dialogFormVisible: false,
      modelList: []
    }
  },
  watch: {
    filterListJson: {
      handler(jsonArr) {
        const arr = jsonArr
          .filter((a) => a.isNecessary)
          .map((a) => {
            if (a.filed === 'companyId') {
              return {
                ...a,
                value: '',
                option: this.companyDrapdown
              }
            } else if (a.filed === 'supplierClearenceIds') {
              return {
                ...a,
                value: '',
                option: this.pickUpOptions
              }
            } else if (a.filed === 'originHarborCodes') {
              return {
                ...a,
                value: '',
                option: this.originOptions
              }
            } else if (a.filed === 'destinationHarborCodes') {
              return {
                ...a,
                value: '',
                option: this.destinationOptions
              }
            } else if (a.filed === 'saleIds') {
              return {
                ...a,
                value: '',
                option: this.userOptions
              }
            } else {
              if (a.optionJson) {
                return {
                  ...a,
                  value: '',
                  option: JSON.parse(a.optionJson)
                }
              } else {
                return {
                  ...a,
                  value: ''
                }
              }
            }
          })
        this.modelList = arr
      },
      deep: true,
      immediate: true
    }
  },
  methods: {
    ok() {
      this.$emit('ok')
      this.dialogFormVisible = false
    },
    changeItem(value, index) {
      this.$set(this.modelList, index, {
        ...this.modelList[index],
        value
      })
      this.$refs.baseMutiInput?.reset()
    },
    reset() {
      this.modelList.forEach((a, index) => {
        this.$set(this.modelList, index, {
          ...a,
          value: ''
        })
      })
    },
    getData() {
      const obj = {
        dateItems: []
      }
      this.modelList.forEach(a => {
        switch (a.type) {
          case 0:
          case 2:
          case 8:
          case 5:
          case 6:
          if (a.value !== '') {
            obj[a.filed] = a.value
          }
          break
          case 1:
          case 200:
            if (a.value !== '' && a.value.length > 0) {
              obj[a.filed] = a.value
            }
          break
          case 7:
            if (a.value !== '' && a.value.length > 0) {
              obj.dateItems.push({
                type: a.filed,
                start: a.value[0],
                end: a.value[1]
              })
            }
          break
          case 300:
            if (a.value !== '' && a.value.length > 0) {
              obj[a.filed] = a.value.join(';')
            }
          break
        }
      })
      if (obj.dateItems.length === 0) {
        delete obj.dateItems
      }
      return obj
    }
  }
}
</script>
<style lang="less" scoped>
.content {
  width: 100%;
  height: auto;
  .btn {
    width: 120px;
    height: 30px;
    margin-top: 10px;
    margin-left: 94px;
    border-radius: 4px 4px 4px 4px;
    opacity: 1;
    border: 1px solid #eeeeee;
    font-size: 14px;
    font-family: Microsoft YaHei-Regular, Microsoft YaHei;
    font-weight: 400;
    color: #999999;
    line-height: 30px;
    text-align: center;
    cursor: pointer;
  }
}
</style>
