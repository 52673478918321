<template>
  <el-dialog
    v-drag
    title="编辑筛选项"
    :visible.sync="dialogFormVisible"
    width="640px"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    :before-close="cancel"
    append-to-body
  >
    <div class="content">
      <div class="right">
        <div class="title">未分配菜单</div>
        <div v-for="(item, $index) in unCheckedArr" :key="$index" class="panel">
          <el-checkbox v-model="item.checked">{{ item.label }}</el-checkbox>
        </div>
      </div>
      <div class="arrow_content">
        <div title="向右移动并保存设置" class="arrow flex_c_c" @click="right">
          <i class="el-icon-arrow-right"></i>
        </div>
        <div title="向左移动并保存设置" class="arrow flex_c_c" @click="left">
          <i class="el-icon-arrow-left"></i>
        </div>
      </div>
      <div class="left">
        <div class="title">已分配菜单</div>
        <div v-for="(item, $index) in checkedArr" :key="$index" class="panel">
          <el-checkbox v-model="item.checked">{{ item.label }}</el-checkbox>
        </div>
      </div>
    </div>
    <span slot="footer" class="footer flex_c_c">
      <div class="cancel flex_c_c" @click="cancel">取 消</div>
      <div class="ok flex_c_c" @click="ok">保存设置</div>
    </span>
  </el-dialog>
</template>
<script>
import { TableUserAdd } from '@/api/system'
export default {
  props: {
    dialogFormVisible: {
      type: Boolean,
      default: false
    },
    filterListJson: {
      type: Array,
      default: function() {
        return []
      }
    },
    filterListId: {
      type: String,
      default: ''
    },
    tableCode: {
      type: String,
      default: 'MBLHoldRecord'
    }
  },
  data() {
    return {
      checkedArr: [],
      unCheckedArr: []
    }
  },
  watch: {
    dialogFormVisible(value) {
      if (value) {
        this.checkedArr = this.filterListJson
          .filter((a) => a.isNecessary)
          .map((a) => {
            return {
              ...a,
              checked: false
            }
          })
          .sort((a, b) => a.orderSeq - b.orderSeq)
        this.unCheckedArr = this.filterListJson
          .filter((a) => !a.isNecessary)
          .map((a) => {
            return {
              ...a,
              checked: false
            }
          })
          .sort((a, b) => a.orderSeq - b.orderSeq)
      }
    }
  },
  methods: {
    cancel() {
      this.$emit('cancel')
    },
    right() {
      const checkedArr = this.unCheckedArr.filter((a) => a.checked)
      const unCheckedArr = this.unCheckedArr.filter((a) => !a.checked)
      this.$nextTick(() => {
        this.unCheckedArr = unCheckedArr.map((a) => {
          return {
            ...a,
            checked: false
          }
        })
        this.checkedArr = [...this.checkedArr, ...checkedArr]
          .map((a) => {
            return {
              ...a,
              checked: false
            }
          })
          .sort((a, b) => a.orderSeq - b.orderSeq)
      })
    },
    left() {
      const checkedArr = this.checkedArr.filter((a) => a.checked)
      const unCheckedArr = this.checkedArr.filter((a) => !a.checked)
      this.$nextTick(() => {
        this.checkedArr = unCheckedArr.map((a) => {
          return {
            ...a,
            checked: false
          }
        })
        this.unCheckedArr = [...this.unCheckedArr, ...checkedArr]
          .map((a) => {
            return {
              ...a,
              checked: false
            }
          })
          .sort((a, b) => a.orderSeq - b.orderSeq)
      })
    },
    async ok() {
      const arr = []
      this.unCheckedArr.forEach((a) => {
        arr.push({
          ...a,
          isNecessary: false
        })
      })
      this.checkedArr.forEach((a) => {
        arr.push({
          ...a,
          isNecessary: true
        })
      })
      arr.sort((a, b) => a.orderSeq - b.orderSeq)
      console.log(arr)
      try {
        const res = await TableUserAdd({
          tableCode: this.tableCode,
          value: JSON.stringify(arr),
          type: 1,
          id: this.filterListId || undefined
        })
        if (res.success) {
          this.$message.success('筛选条件添加成功')
          this.$emit('ok')
        } else {
          this.$message.error(res.errorMessage)
        }
      } catch (error) {
        console.log(error)
      }
    }
  }
}
</script>
<style scoped lang="less">
.content {
  width: calc(100% - 40px);
  height: 680px;
  display: flex;
  max-height: 550px;
  margin: 0 auto;
}
.right {
  width: 45%;
  height: 100%;
  overflow: auto;
  border: 1px solid #eeeeee;
  border-radius: 5px;
}
.arrow_content {
  width: 10%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.arrow_content div {
  margin: 10px 0;
}
.left {
  width: 45%;
  height: 100%;
  overflow: auto;
  border: 1px solid #eeeeee;
  border-radius: 5px;
}
.title {
  text-align: center;
  color: #303133;
  line-height: 53px;
  height: 53px;
  width: 100%;
  font-size: 16px;
  font-weight: 400;
  color: #666666;
  border-bottom: 1px solid #eeeeee;
}
.arrow {
  width: 40px;
  height: 40px;
  font-size: 20px;
  border: 1px solid #eeeeee;
  border-radius: 4px 4px 4px 4px;
  color: #666666;
  box-sizing: border-box;
}
.arrow:hover {
  color: #409eff;
  border: 1px solid #409eff;
  cursor: pointer;
}
.footer {
  width: 100%;
  display: flex;
  justify-content: center;
  .cancel {
    width: 120px;
    height: 40px;
    background: #eeeeee;
    border-radius: 4px 4px 4px 4px;
    font-size: 14px;
    font-family: Microsoft YaHei-Regular, Microsoft YaHei;
    font-weight: 400;
    color: #999999;
    margin-right: 16px;
    cursor: pointer;
  }
  .ok {
    width: 120px;
    height: 40px;
    background: #2395f3;
    border-radius: 4px 4px 4px 4px;
    font-size: 14px;
    cursor: pointer;
    font-family: Microsoft YaHei-Regular, Microsoft YaHei;
    font-weight: 400;
    color: #ffffff;
  }
}
.panel {
  margin-top: 20px;
  margin-left: 20px;
}
</style>
